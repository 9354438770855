<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-cierre-novedad-export"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Generar Resumen Cierres Novedades</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-lg-11">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          id="num_inspeccion"
                          v-model="form.id"
                          class="custom-control-input"
                        />
                        <label
                          class="custom-control-label"
                          for="num_inspeccion"
                        >
                          # Inspección
                        </label>
                      </div>
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          id="viaje_id"
                          v-model="form.viaje_id"
                          class="custom-control-input"
                        />
                        <label
                          class="custom-control-label"
                          for="viaje_id"
                        >
                          # Viaje
                        </label>
                      </div>
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          id="fecha_inicio"
                          v-model="form.fecha_inicio"
                          class="custom-control-input"
                        />
                        <label
                          class="custom-control-label"
                          for="fecha_inicio"
                        >
                        Fecha Inicio
                        </label>
                      </div>
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          id="fecha_fin"
                          v-model="form.fecha_fin"
                          class="custom-control-input"
                        />
                        <label
                          class="custom-control-label"
                          for="fecha_fin"
                        >
                        Fecha Fin
                        </label>
                      </div>
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          id="formato_inspeccion_id"
                          v-model="form.formato_inspeccion_id"
                          class="custom-control-input"
                        />
                        <label
                          class="custom-control-label"
                          for="formato_inspeccion_id"
                        >
                        Formato Inspección
                        </label>
                      </div>
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          id="sitio_type"
                          v-model="form.sitio_type"
                          class="custom-control-input"
                        />
                        <label
                          class="custom-control-label"
                          for="sitio_type"
                        >
                          Tipo de Sitio
                        </label>
                      </div>
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          id="sitio_type2"
                          v-model="form.sitio_type2"
                          class="custom-control-input"
                        />
                        <label
                          class="custom-control-label"
                          for="sitio_type2"
                        >
                          Sitio/Punto Gestión
                        </label>
                      </div>
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          id="empresa_id"
                          v-model="form.empresa_id"
                          class="custom-control-input"
                        />
                        <label
                          class="custom-control-label"
                          for="empresa_id"
                        >
                          Empresa
                        </label>
                      </div>
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          id="tipoCombustible"
                          v-model="form.tipoCombustible"
                          class="custom-control-input"
                        />
                        <label
                          class="custom-control-label"
                          for="tipoCombustible"
                        >
                          Tipo Combustible
                        </label>
                      </div>
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          id="vehiculo_id"
                          v-model="form.vehiculo_id"
                          class="custom-control-input"
                        />
                        <label
                          class="custom-control-label"
                          for="vehiculo_id"
                        >
                        Vehículo
                        </label>
                      </div>
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          id="conductor_id"
                          v-model="form.conductor_id"
                          class="custom-control-input"
                        />
                        <label
                          class="custom-control-label"
                          for="conductor_id"
                        >
                        Conductor
                        </label>
                      </div>
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          id="estado"
                          v-model="form.estado"
                          class="custom-control-input"
                        />
                        <label
                          class="custom-control-label"
                          for="estado"
                        >
                        Estado
                        </label>
                      </div>
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          id="estado_documento"
                          v-model="form.estado_documento"
                          class="custom-control-input"
                        />
                        <label
                          class="custom-control-label"
                          for="estado_documento"
                        >
                        Estado Documento
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-md-3">
                      <label># Inspección</label>
                      <input
                        type="number"
                        v-model="filtros.id"
                        placeholder="# inspeccion"
                        label="id"
                        class="form-control form-control-sm p-0"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label># Viaje</label>
                      <input
                        type="number"
                        v-model="filtros.viaje_id"
                        placeholder="# viaje"
                        label="viaje_id"
                        class="form-control form-control-sm p-0"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label>Rango Fechas</label>
                      <div class="row">
                        <div class="col-md-6">
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="filtros.fecha_inicio"
                          />
                        </div>
                        <div class="col-md-6">
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="filtros.fecha_fin"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-12">
                      <label>Formato</label>
                      <v-select
                        :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                        v-model="listasForms.slct_formato_inspeccion"
                        placeholder="Formato Inspección"
                        label="nombre"
                        class="form-control form-control-sm p-0"
                        :options="$parent.formatos_inspeccion"
                        :filterable="false"
                        @search="$parent.buscarFormatosInspeccion"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-4">
                      <label>Tipo de sitio</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="filtros.sitio_type"
                        @change="getTipoSitio"
                      >
                        <option value>Seleccione...</option>
                        <option
                          v-for="estado in $parent.listasForms.sitio_types"
                          :key="estado.numeracion"
                          :value="estado.numeracion"
                          >{{ estado.descripcion }}</option
                        >
                      </select>
                    </div>
                    <div
                      class="form-group col-md-8"
                      v-if="filtros.sitio_type2 == 'App\\PuntoGestion'"
                    >
                      <label>Punto Gestión</label>
                      <v-select
                        :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                        v-model="listasForms.slct_puntos_gestion"
                        placeholder="Puntos Gestión"
                        label="nombre"
                        class="form-control form-control-sm p-0"
                        :options="$parent.puntos_gestion"
                        :filterable="false"
                        @search="$parent.buscarPuntosGestion"
                      ></v-select>
                    </div>
                    <div
                      class="form-group col-md-8"
                      v-else-if="filtros.sitio_type2 == 'App\\Sitio'"
                    >
                      <label>Sitio</label>
                      <v-select
                        :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                        v-model="listasForms.slct_sitio"
                        placeholder="Sitio"
                        label="nombre"
                        class="form-control form-control-sm p-0"
                        :options="$parent.sitios"
                        :filterable="false"
                        @search="$parent.buscarSitios"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-8" v-else></div>
                    <div class="form-group col-md-12">
                      <label>Empresa</label>
                      <v-select
                        :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                        v-model="listasForms.slct_empresa"
                        placeholder="Empresas"
                        label="razon_social"
                        class="form-control form-control-sm p-0"
                        :options="$parent.empresas"
                        :filterable="false"
                        @search="$parent.buscarEmpresas"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-6">
                      <label>Tipo Combustible</label>
                      <v-select
                        :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                        v-model="filtros.tipoCombustible"
                        placeholder="Combustible"
                        label="descripcion"
                        class="form-control form-control-sm p-0"
                        :options="$parent.listasForms.tiposCombustible"
                        :filterable="false"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-6">
                      <label>Vehículo</label>
                      <v-select
                        :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                        v-model="listasForms.slct_vehiculo"
                        placeholder="Placa"
                        label="placa"
                        class="form-control form-control-sm p-0"
                        :options="$parent.vehiculos"
                        :filterable="false"
                        @search="$parent.buscarCabezotes"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-12">
                      <label>Conductor</label>
                      <v-select
                        :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                        v-model="listasForms.slct_conductor"
                        placeholder="Conductor"
                        label="nombre"
                        class="form-control form-control-sm p-0"
                        :options="$parent.conductores"
                        :filterable="false"
                        @search="$parent.buscarConductor"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-6">
                      <label>Estado</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="filtros.estado"
                      >
                        <option value>Seleccione...</option>
                        <option
                          v-for="estado in $parent.listasForms.estados"
                          :key="estado.numeracion"
                          :value="estado.numeracion"
                          >{{ estado.descripcion }}</option
                        >
                      </select>
                    </div>
                    <div class="form-group col-md-6">
                      <label>Estado Documento</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="filtros.estado_documento"
                      >
                        <option value>Seleccione...</option>
                        <option
                          v-for="estado in $parent.listasForms.estados_documentos"
                          :key="estado.numeracion"
                          :value="estado.numeracion"
                          >{{ estado.descripcion }}</option
                        >
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-if="$store.getters.can('hidrocarburos.cierreNovedad.exportExcel')"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "CierreNovedadExport",
  components: { 
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      inspecciones: {},
      form: {
        id: true,
        viaje_id: true,
        fecha_inicio: true,
        fecha_fin: true,
        formato_inspeccion_id: true,
        sitio_type: true,
        sitio_type2: true,
        empresa_id: true,
        tipoCombustible: true,
        vehiculo_id: true,
        conductor_id: true,
        estado: true,
        estado_documento: true,
      },
      filtros: {
        id: null,
        viaje_id: null,
        fecha_inicio: null,
        fecha_fin: null,
        formato_inspeccion_id: null,
        sitio_id: null,
        sitio_type: null,
        sitio_type2: null,
        empresa_id: null,
        tipoCombustible: null,
        vehiculo_id: null,
        conductor_id: null,
        estado: null,
        estado_documento: null,
      },
      listasForms: {
        slct_formato_inspeccion: [],
        slct_puntos_gestion: [],
        slct_sitio: [],
        slct_empresa: [],
        tiposCombustible: [],
        slct_vehiculo: [],
        slct_conductor: [],
        estados: [],
        estados_documentos: [],
        sitio_types: [],
      },
    };
  },

  methods: {
    getTipoSitio(){
      this.filtros.sitio_id = null;
      if (this.filtros.sitio_type) {
        if (this.filtros.sitio_type == 1) {
          this.filtros.sitio_type2 = "App\\Sitio";
          this.filtros.sitio_id = null;
          this.$parent.listasForms.slct_puntos_gestion = null;
          if (this.$parent.listasForms.slct_sitio) {
            this.filtros.sitio_id = this.$parent.listasForms.slct_sitio.id;
          }
        } else {
          this.filtros.sitio_type2 = "App\\PuntoGestion";
          this.$parent.listasForms.slct_sitio = null;
          if (this.$parent.listasForms.slct_puntos_gestion) {
            this.filtros.sitio_id = this.$parent.listasForms.slct_puntos_gestion.id;
          }
        }
      } else {
        this.filtros.sitio_type2 = null;
        this.$parent.listasForms.slct_puntos_gestion = null;
        this.$parent.listasForms.slct_sitio = null;
      }
    },

    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/cierreNovedades/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          if (!data.error) {
            this.$swal({
              icon: "success",
              title: data.message,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            location.href = data.url;
          } else {
            this.$swal({
              icon: "error",
              title: data.error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },
};
</script>
